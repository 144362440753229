export default ({
    namespaced: true,
    actions: {},
    mutations: {
        Flagt(state){
            state.isShowli = true
        },
        Flagf(state){
            state.isShowli = false
        }
    },
    state: {
        nav: [
			{
			    title: '明日票通',
			    subtitle: 'fleetweet'
			},
            {
                title: '介绍',
                subtitle: 'Introduce'
            }
        ],
        Games: [
            {
                name: 'Game',
                content: ['大众创业', '热衷于搞钱养家', '免费使用', '定位准确', '司机的定价权'],
            },
            {
                name: 'Store',
                content: ['君子竞争', '代驾圈', '司机的定价权', '车队','轻资产'],
            },
        ],
        isShowli: false

    },
    getters: {}
})