<template>
  <div class="box">
    <el-container>
      <el-header>
        <Header />
      </el-header>
      <el-main>
        <Home />
      </el-main>
      <el-footer>
        <Footer />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Header from "./Header/index.vue";
import Home from "./Home/index.vue";
import Footer from "./Footer/index.vue";
export default {
  name: "Main",
  components: {
    Header,
    Home,
    Footer,
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 1920px;
  margin: 0 auto;
  .el-container {
    width: auto;
    background-color: rgb(238, 238, 238);
  }
  .el-header {
    width: auto;
    height: 360px !important;
  }
  .el-main {
    width: auto;
    height: auto;
  }
  .el-footer {
    width: 1903px;
    height: 265px !important;
    padding: 45px 0;
    background-color: #e3e2e2;
    color: #9f9378;
    font-size: 12px;
    line-height: 24px;
  }
}
</style>