<template>
  <div class="footer">
    <!-- 页脚左侧 -->
    <div class="foot_lefts">
      <a href="#" class="lefts">
		  <img src="../../assets/logo.png" style="width: 60px;height: 60px;"/>
	  </a>
	  
      <div class="rights">
        <span class="logo-riot">
		</span>
      </div>
    </div>
	<span style="position: relative;left: -9rem;">
		吉林省明日司服科技有限公司旗下产品:明日票通
	</span>
    <!-- 页脚右侧 -->
    <div class="foot_links">
      <p>
        <a href="#" v-for="(item, index) in link.map" :key="index" @click="weqc(item)"
          >{{ item }}
          <span v-if="link.map.length - 1 === index ? false : true">|</span>
        </a>
      </p>
      <p>
        <a href="#" v-for="item in link.zh" :key="item">{{ item }}</a>
      </p>
      <p v-for="item in link.pis" :key="item">{{ item }}</p>
      <p @click="gourl">
        <img v-if="link.public._src" :src="link.public._src" />
        <a href="#" v-for="(item, index) in link.public.title" :key="item"
          >{{ item }}
          <span v-if="link.public.title.length - 1 === index ? false : true"
            >|</span
          >
        </a>
      </p>
      <p>{{ link.private }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Footer",
  computed: {
    ...mapState("ComonFooter", ["link"]),
  },
  mounted() {
    console.log(this.link);
  },
  methods:{
	  weqc(e){
		  console.log(e);
		  if (e == '明日票通') {
		  	
		  } if (e == '套餐隐私协议'){
			  window.open(`https://h5.guankuodev.com/#/pages/agreement/combo`, '_blank')
		  }else if (e == '用户协议'){
			window.open(`https://h5.guankuodev.com/#/pages/agreement/userAgreement`, '_blank')
		  }else if(e == '隐私政策'){
			  window.open(`https://h5.guankuodev.com/#/pages/agreement/privacyPolicy`, '_blank')
		  }
	  },
	  gourl(){
		  window.open(`https://beian.miit.gov.cn/#/Integrated/index?recordcode=2022006719`, '_blank')
	  }
  }
  
};
</script>

<style lang="less" scoped>
.footer {
  width: 1358px;
  height: 175px;
  padding: 15px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  // 页脚左侧
  .foot_lefts {
    width: 127px;
    height: 100px;
    line-height: 100px;
    display: flex;
    justify-content: space-between;

    .lefts {
      width: 189px;
      height: 34px;
      margin: 10px 0 0 10px;
      background: url('/src/assets/logo.png')
        no-repeat center;
      background-position: -216px 0;
    }

    .rights {
      width: 298px;
      height: 28px;
      background-size: 98px 28px;
      background-position: 0px 0px;
      width: 98px;
      height: 28px;
      margin-top: 10px;
    }
  }
  // 页脚右侧
  .foot_links {
    width: 750px;
    height: 100%;
    // background-color: orange;

    a {
      color: #9f9378;
    }

    p:first-child {
      height: 25px !important;
      line-height: 25px;
      overflow: hidden;
    }

    p:nth-child(2) {
      a {
        padding-right: 10px;
      }
    }

    p:nth-child(n + 1) {
      margin: 0;
      padding: 0;
      height: 20px;
      line-height: 20px;
    }

    a:hover {
      color: orange;
    }
  }
}
</style>

