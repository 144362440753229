<template>
  <!-- 很想写注释 但是写着写着就忘加了 哈哈 -->
  <div class="mainContent">
    <!-- 轮播图 -->
    <el-card :body-style="{ padding: '0px' }" class="box-card lbt">
      <div class="Lbt" @mouseenter="come" @mouseleave="out">
        <div class="promo-img-list" ref="move" @changeMove="Go">
          <img v-for="(m, index) in images" :key="index" :src="m" />
        </div>
        <div class="promo-title-list">
          <span
            v-for="(item, index) in titleList"
            :class="isClass === index ? 'liang' : ''"
            @mouseenter="log(index)"
            :key="item.title"
            >{{ item }}</span
          >
        </div>
      </div>
    </el-card>
    <!-- news新闻 -->
    <el-card :body-style="{ padding: '0px' }" class="box-card m-news">
      <ul class="part-tab-title">
        <li
          @mouseenter="changNum(index, item.Name)"
          :class="num === index ? 'biao' : ''"
          v-for="(item, index) in news"
          :key="index"
        >
          {{ item.Tit }}
        </li>
      </ul>
      <h2>{{ synthetical.HH[num] }}</h2>
      <ul class="new-tab-content-ul">
        <li @roy="roy" v-for="(item, index) in roys" :key="index">
          <span class="item-type" :class="item.class">{{ item.title }}</span>
          <a href="#">{{ item.content }}</a>
          <span class="item-time">{{ item.times }}</span>
        </li>
      </ul>
	  <p>
		  1.您主动提供给我们的信息：
		  （1）帮助您成为我们的用户，您填写的注册信息，包括您电话号码，以用于注册、登录账户产品和服务，及我们的客服和售后为您提供咨询服务，并保障您的账户安全。如不提供，您将仅能浏览、了解，无法享受相关服务；如果您仅需浏览、了解，您不需要注册成为我们的用户及提供上述信息；
		  （2）当您与我们联系时，我们可能会保存您的通信或通话记录和内容或您留下的联系方式，以便帮助您解决问题，或记录相关问题的处理方案及结果。我们的客服会使用您的帐号信息与您核验身份。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息。如不提供，不会影响您使用本产品的其他功能和服务。
		  2. 我们在您使用服务过程中收集的信息，除法律规定情形外我们都将事先征得您的同意：
		  （1）在您首次使用打开app时，我们将在取得您的授权后，接收并记录您所使用的设备相关信息，包括设备属性信息（IMEI、IMSI、设备型号、操作系统版本、设备设置、唯一设备标识符软硬件特征信息）、设备连接信息（IP地址、电信运营商、访问日期和时间），以保障您的账户及交易安全；
		  （2）在您首次使用打开app时，我们将在您授权后读取您的手机状态，用以保障app应用服务的正常使用，更准确定位和解决在使用时遇到的问题，改进及优化服务体验，以及保障您的账号安全；
		  （3）在您首次使用打开明日票通app、拍照提问、保存、上传图片（根据您的设备系统不同可能会有场景差异）时，我们将申请您的存储和相册权限，以用于拍照提问功能、保存图片、文件或缓存信息到手机；
		  （4）在您参与部分虚拟指定区域的活动时，我们将申请您的地理位置权限，以更准确的核实您是否有参与该活动的资格；
		  （5）在您首次使用拍照提问、上传图片、编辑个人资料头像调取摄像头拍摄功能时，我们将申请获取您的摄像头权限，以用于使用拍照功能；
	  </p>
	 
    </el-card>
	<el-card  style="background-color:  #fff !important;;padding: 1px !important;margin:  0 !important;margin-top: 20px;">
		<p >
			<h2 style="margin-top: 10px;margin-bottom: 10px;font-weight: 900;">企业文化</h2>
		</p>
		<p>
			山之高处人为峰，企之共赢人作本。员工与客户合为企业之本，合-方能共赢。公司以德育人，思想道德建设，建设跨时代的精神文明;公司关注人才，关注管理人才、专业技术人才、技能操作人才;公司人力优势，打造着强的员工实体队伍。
			
			企业缺少了先进的管理，就缺少了前行的动力。公司管理制胜，一以贯之的永恒主题，是维系“合”文化的脊梁，管理与文化融合贯通，有机缔结。
			
			说得天花乱坠，喊破嗓子，不如干出样子。公司班子以人为本的原则，用先进文化充实武装，企业中心任务，求真务实，工作。而今，公司班子和中层干部队伍已一支朝气蓬勃、拼搏奉献、精干高效、勤政为民的战斗集体，引领着职工走在时代前沿。前期，公司本着精简、效能的原则对内部机构了但斧的与，现代企业制度的要求，以事定岗，以岗定责，以责定人，为全体员工展帆引航。
			
			惟有创新活力来，管理创新驱动着公司工作的“三效”并优：
			
			效力-组织动员力强，工作力强，约束力强，协作力强，开拓创新力更强;效益-不但了经济效益，而且了社会效益，打出了供电人的服务品牌;效应-家看到的虚与蛇、推诿扯皮，而是别开生面的带动效应、优化效应和示范效应。
			
			德为事业之基。基业长青的企业必定有能够支撑企业持续发展的核心理念，而理念注定(更多精彩文章来自“秘书不求人”)于员工的精神世界，这就咱潍坊供电人集体智慧的结晶-“合”文化。
			
			厚德以载物。企业道德具备了企业文化的特征，属于企业文化的高层次意识。“德若水之源，才若水之波”，公司的企业道德挥发着无与伦比的强辐射力：因“德”，“才”一直正轨向前延展。以“德”为基，施行管理，提升职工整体队伍素质，秉承以德为万事之根的理念，把员工道德品质的精华凝集，力求德才兼备。“德”把客户呼声信号，把客户利益放到位，把客户满意标准，与客户架起诚信的亮丽彩虹桥，着企业与客户的“合”力共赢。
			
			“合”之要义，铸就强体。“合”文化内化于人心，外化于人行，贯穿于管理生产经营，体现于潍电的滴滴点点。合，凝结理念以升华，劲射精彩生熠辉，企业再升级!
			
			企业人，文化魂。的文化-高屋建瓴，求真务实;的文化-干事创业之信念、做人做事之准绳;的文化-企业管理的哲学，潜入深处，将员工齐聚。
			
			一路沿革“合”文化，供电人重整装、再齐发，“合”力迎新潮踏步走来。
		</p>
		<p>
			<h2 style="margin-top: 10px;margin-bottom: 10px;font-weight: 900;">企业文化：发展理念</h2>
		</p>
		<p>
			发展理念：先人一步持续提升
			释义：先人一步，遍地黄金。研判宏观势、市场趋势、行业走势，洞察先机、抢占先机、赢得先机。坚持战略引领、风险可控原则，秉承规模增长与价值增长并重、价值增长优先，产业运营与资本运营并举、产业运营为主的发展思路，科学决策，诚信立业，不断提升企业可持续发展能力。
		</p>
		<p>
			<h2 style="margin-top: 10px;margin-bottom: 10px;font-weight: 900;">企业文化：管理理念</h2>
		</p>
		<p>
			管理理念：三化为本创新驱动
			
			释义：以“制度化、规范化、市场化”为内容的三化管理是企业规范高效运作的活力之本。“制度化”是基础，“规范化”是保障，“市场化”是核心。依靠创新驱动，“三化”有机融合，激发管理内生动力，激活资源要素活力，持续提升企业核心竞争力。
		</p>
		<p>
			<h2 style="margin-top: 10px;margin-bottom: 10px;font-weight: 900;">核心价值观</h2>
		</p>
		<p>诚实、守信、勤勉、谦逊、宽容、担当</p>
		<p>
			<h2 style="margin-top: 10px;margin-bottom: 10px;font-weight: 900;">企业愿景</h2>
		</p>
		<p>立足长春市,面向全中国,力争成为中国科技 企业的先锋,行业的带头兵.</p>
		<p>
			<h2 style="margin-top: 10px;margin-bottom: 10px;font-weight: 900;">服务宗旨</h2>
		</p>
		<p>我们所要做的不只是单纯地守护代驾司机,而是树立一中优雅、健康、积极向上的生活方式,带给您生活的品质和品味.</p>
		<p>
			<h2 style="margin-top: 10px;margin-bottom: 10px;font-weight: 900;">员工信念</h2>
		</p>
		<p>
			(1) 兢兢业业,不掉以轻心;为团队争取荣誉,不计较个人得失;为公司创造价值,坚持不懈.
			<br />
			(2)组解决工作中的困难,责无旁贷;援助同事生活中的困难,义不容辞.
		</p>
		<p>
			<h2 style="margin-top: 10px;margin-bottom: 10px;font-weight: 900;">企业作风</h2>
		</p>
		<p>
			 团结 务实 诚信 担当
			 <br />
			团结——团结就是力量。容上容下，容人容已，上下一心、精诚协作。 务实——讲求实际，实事求是，说实话、办实事、重实际、求实效。
			
			诚信——以诚相待，以信相交，信守承诺，言必行，行必果。
			
			担当——摒弃个人得失观，强调责任感。工作不推诿，不扯皮，有责任意识，勇于承担责任，能够担当重任。
		</p>
		
		<p>
			<h2 style="margin-top: 10px;margin-bottom: 10px;font-weight: 900;">企业人才观</h2>
		</p>
		<p>
			以发展来吸引人：通过公司的快速发展，为员工提供个人发展的空间和成就事业的平台；通过公司品牌实力、文化理念、人性化的制度吸引优秀人才的加入。
			
			以事业来凝聚人：有钱不一定有事业，有事业一定不缺钱，共同的目标、共同的事业将各方优秀人才凝聚在一起。员工依托公司强有力的平台，通过自身的努力，充分实现自己的人生价值。
			
			以工作来培养人：“从基层做起、步步提升”与“破格提拔”相结合，让员工在工作中锻炼，不断进步，不断成长。
			
			以结果来考核人：不谈借口和理由，注重员工实际的工作能力和所提供的结果。
		</p>
		<p>
			<h2  style="margin-top: 10px;margin-bottom: 10px;font-weight: 900;">企业价值观</h2>
		</p>
		
		<p>
			客户第一：关注客户的关注点，为客户提供建议和资讯，帮助客户成长；
			团队合作：共享共担，以小我完成大我；
			拥抱变化：突破自我，迎接变化；
			诚信：诚实正直，信守承诺；
			激情：永不言弃，乐观向上；
			敬业：以专业的态度和平常的心态做非凡的事情。
		</p>
		<p>
			<h2  style="margin-top: 10px;margin-bottom: 10px;font-weight: 900;">入职誓言</h2>
		</p>
		
		<p>
			今天我选择挑战，道路充满艰辛，更有无限机遇。我要全力以赴，创造人生奇迹。让我们从现在开始， 对人感恩，对己克制，对事尽力，对物珍惜。
		</p>
		
	</el-card>
	<el-card style="background-color:  #fff !important;;padding: 1px !important;margin:  0 !important;margin-top: 20px;" >
			<p style="margin-bottom: 20px;">三方SDK：</p>
			<table style="border-color: #888888;" border="1" class="mt">
				<tr>
					<td class="top-p"><p class="td-p">功能类型</p></td>
					<td class="top-p"><p class="td-p">sdk名称</p></td>
					<td class="top-p"><p class="td-p">使用目的</p></td>
					<td class="top-p"><p class="td-p diverse1">第三方名称</p></td>
					<td class="top-wide"><p class="td-p diverse2">手机的个人信息种类</p></td>
					<td class="top-wide"><p class="td-p diverse3">第三方隐私政策链接</p></td>
				</tr>
				<tr>
					<!-- 链接以完成 -->
					<td class="td-common"><p class="td-p2">支付服务</p></td>
					<td class="td-common"><p class="td-p2 p-width">支付宝支付</p></td>
					<td class="td-common"><p class="td-p2">确认用户支付指令并完成支付</p></td>
					<td class="td-common"><p class="td-p2">支付宝</p></td>
					<td class="td-big"><p class="td-p2 wai">订单号和订单金额</p></td>
					<td class="td-medium">
						<p class="p-a">
							<a class="td-a" href="https://render.alipay.com/p/yuyan/180020010001196791/preview.html?agreementId=AG00000132">
								https://
								render.a
								lipay.co
								m/p/yuya
								n/180020
								01000119
								6791/pre
								view.htm
								l?agreem
								entId=AG
								00000132
							</a>
						</p>
					</td>
				</tr>
				<tr>
					<!-- 链接已完成 -->
					<td class="td-common"><p class="td-p2">支付服务</p></td>
					<td class="td-common"><p class="td-p2">微信支付</p></td>
					<td class="td-common"><p class="td-p2">确认用户支付指令并完成支付</p></td>
					<td class="td-common"><p class="td-p2">财付通支付科技有限公司</p></td>
					<td class="td-big"><p class="td-p2 wai">订单号和订单金额</p></td>
					<td class="td-medium">
						<p class="p-a">
							<a class="td-a" href="https://pay.weixin.qq.com/static/protocol/protocol_individual.shtml">
								https://
								pay.weix
								in.qq.co
								m/static
								/protoco
								l/protoc
								ol_indiv
								idual.sh
								tml
							</a>
						</p>
					</td>
				</tr>
				<tr>
					<!-- 链接已完成 -->
					<td class="td-common"><p class="td-p2">支付服务</p></td>
					<td class="td-common"><p class="td-p2">花呗支付</p></td>
					<td class="td-common"><p class="td-p2">确认用户支付指令并完成支付</p></td>
					<td class="td-common"><p class="td-p2">蚂蚁科技集团股份有限公司</p></td>
					<td class="td-big"><p class="td-p2 wai">订单号和订单金额</p></td>
					<td class="td-medium">
						<p class="p-a">
							<a class="td-a" href="https://render.alipay.com/p/yuyan/180020010001196791/preview.html?agreementId=AG00000174">
								https://
								render.a
								lipay.co
								m/p/yuya
								n/180020
								01000119
								6791/pre
								view.htm
								l?agreem
								entId=AG
								00000174
							</a>
						</p>
					</td>
				</tr>
				<tr>
					<!-- 链接以完成 -->
					<td class="td-common"><p class="td-p2">支付服务</p></td>
					<td class="td-common"><p class="td-p2">找朋友代付</p></td>
					<td class="td-common"><p class="td-p2">确认用户支付指令并完成支付</p></td>
					<td class="td-common"><p class="td-p2">支付宝</p></td>
					<td class="td-big"><p class="td-p2 wai">订单号和订单金额</p></td>
					<td class="td-medium">
						<p class="p-a">
							<a class="td-a" href="https://render.alipay.com/p/yuyan/180020010001196791/preview.html?agreementId=AG00000132">
								https://
								render.a
								lipay.co
								m/p/yuya
								n/180020
								01000119
								6791/pre
								view.htm
								l?agreem
								entId=AG
								00000132
							</a>
						</p>
					</td>
				</tr>
				<tr>
					<!-- 链接完成 -->
					<td class="td-common"><p class="td-p2">支付服务</p></td>
					<td class="td-common"><p class="td-p2">云闪付支付</p></td>
					<td class="td-common"><p class="td-p2">确认用户支付指令并完成支付</p></td>
					<td class="td-common"><p class="td-p2">中国银联股份有限公司</p></td>
					<td class="td-big"><p class="td-p2 wai">订单号和订单金额</p></td>
					<td class="td-medium">
						<p class="p-a">
							<a class="td-a" href="https://user.95516.com/pages/misc/newAgree.html">
								https://
								user.955
								16.com/p
								ages/mis
								c/newAgr
								ee.html
							</a>
						</p>
					</td>
				</tr>
				<tr>
					<!-- 链接完成 -->
					<td class="td-common"><p class="td-p2">地图服务</p></td>
					<td class="td-common"><p class="td-p2">高德地图</p></td>
					<td class="td-common"><p class="td-p2">用于定位和导航</p></td>
					<td class="td-common"><p class="td-p2">高德软件有限公司</p></td>
					<td class="td-big"><p class="td-p2 wai">设备信息定位信息网络状态</p></td>
					<td class="td-medium">
						<p class="p-a">
							<a class="td-a" href="https://lbs.amap.com/pages/privacy">
								https://
								lbs.amap
								.com/pag
								es/priva
								cy
							</a>
						</p>
					</td>
				</tr>
				<tr>
					<!-- 链接已完成 -->
					<td class="td-common"><p class="td-p2">推送服务</p></td>
					<td class="td-common"><p class="td-p2">极光推送</p></td>
					<td class="td-common"><p class="td-p2">用于推送通知</p></td>
					<td class="td-common"><p class="td-p2">深圳市和讯华谷信息技术有限公司</p></td>
					<td class="td-big"><p class="td-p2 wai">设备信息定位信息网络状态</p></td>
					<td class="td-medium">
						<p class="p-a">
							<a class="td-a" href="https://www.jiguang.cn/license/privacy">
								https://
								www.jigu
								ang.cn/l
								icense/p
								rivacy
			
							</a>
						</p>
					</td>
				</tr>
			</table>
		</el-card>
		
	
 </div>
	
	

</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      num: 0,
      timer: null,
      isClass: 0,
      chang: 0,
    };
  },
  methods: {
    ...mapMutations("CommonHome", ["roy", "firstCals", "remen"]),
    log(index) {
      this.isClass = index;
      // console.log(index);
      this.$emit("changeMove", index);
    },
    Go(i) {
      //    console.log(i);
      this.$refs.move.style.transform = `translateX(-${820 * i}px)`;
    },
    come() {
      clearInterval(this.timer);
      this.timer = null;
    },
    out() {
      this.timer = setInterval(() => {
        this.isClass++;
        this.isClass = this.isClass % this.titleList.length;
        this.Go(this.isClass);
      }, 2000);
    },
    changNum(index, Name) {
      this.num = index;
      //   console.log(this.synthetical[Name]);
      // this.$emit("roy", this.synthetical[Name]);
      this.roy(this.synthetical[Name]);
    },
    Chang(index, Name) {
      this.chang = index;
      this.remen(this.selectLists[Name]);
    },
  },
  computed: {
    ...mapState("CommonHome", [
      "titleList",
      "images",
      "news",
      "synthetical",
      "roys",
      "selectList",
      "selectLists",
      "remens",
      "HeroUpdate",
      "types",
      "zhizhen",
      "zeli",
    ]),
  },
  mounted() {
    this.$on("changeMove", this.Go);
    this.timer = setInterval(() => {
      this.isClass++;
      this.isClass = this.isClass % this.titleList.length;
      this.Go(this.isClass);
    }, 2000);
    this.$on("roy", this.roy);
    this.firstCals();
  },
};
</script>

<style lang="less" scoped>
 .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
.top{
	font-size: 30rpx;
	font-family: "PingFangSC-Medium";
	font-weight: 500;
	color: #000000;
	width: 707rpx;
	margin: 0 auto;
}
.figure{
	margin: 35rpx 25rpx;
	font-size: 30rpx;
	font-family:"PingFangSC-Medium";
	font-weight: 500;
	color: #000000;
}
.fields{
	font-size: 25rpx;
	font-family: "PingFangSC-Regular";
	font-weight: 400;
	color: #393939;
	width: 700rpx;
	margin: 0 auto;
}
.SDK{
	width: 750rpx;
	text-align: center;
	margin: 60rpx 0rpx;
}
.buttom{
	margin-left: 25rpx;
	margin-bottom: 25rpx;
	font-size: 30rpx;
	font-family:"PingFangSC-Medium";
	font-weight: 500;
	color: #000000;
}
.mt{
	border-collapse: collapse;
	width: 707rpx;
	margin: 0 auto;
	margin-top: 70rpx;
	background-color: #fff;
}
.top-p{
	width: 97rpx;
	height: 143rpx;
}
.td-p2{
	font-size: 28rpx;
	font-family: "PingFangSC-Regular";
	font-weight: 400;
	color: #010101;
	width: 80rpx;
	padding-top: 20px;
	padding-bottom: 20px;
	margin: 0 auto;
	text-align: center;
}

.td-p{
	text-align: center;
	font-size: 28rpx;
	font-family: "PingFangSC-Medium";
	font-weight: 500;
	color: #010101;
	text-align: center;
	width: 120rpx;
	margin: 0 auto;
}
.p-width{
	width: 85rpx;
}
.diverse1{
	width: 85rpx;
}
.diverse2{
	width: 143rpx;
}
.diverse3{
	width: 143rpx;
}
.td-common{
	width: 97rpx;
	height: 342rpx;
}
.td-a{
	width: 122rpx;
	color: #206EEF;
	text-decoration:none;
	font-size: 28rpx;
	font-family: "PingFangSC-Regular";
	font-weight: 400;
}
.p-a{
	width: 122rpx;
	text-align: center;
	margin: 0 auto;
}
.td-big{
	width: 173rpx;
}
.wai{
	width: 140rpx;
}

</style>