<template>
  <div class="mask" style=" background-image: linear-gradient(#bbbbbb, #f5f6f9);">
    <div class="comm-topact-inner" >
      <img src="../../assets/logo.png" alt="" style="width: 70px;height: 70px;margin-top: 10px;"/>
      <div class="r">
        <ul class="head-nav" @mouseenter="Flagt">
          <li v-for="item in nav" :key="item.subtitle">
            <a href="#">
              <span class="head-nav-title">{{ item.title }}</span>
              <span class="head-nav-subtitle">{{ item.subtitle }}</span>
            </a>
          </li>
        </ul>

       
      </div>
    </div>

    <div class="masker" v-show="isShowli" @mouseleave="Flagf" >
      <div class="head-nav-sub-inner">
        <ul class="sequence" v-for="(item, index) in Games" :key="index">
          <li v-for="(p, i) in item.content" :key="i">{{ p }}</li>
        </ul>
      </div>
    </div>
    <div class="J_topActVersion" >
      当前版本: <em>Ver 1.0.3</em>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "Header",
  computed: {
    ...mapState("CommonHeader", ["nav", "Games", "isShowli"]),
  },
  methods: {
    ...mapMutations("CommonHeader", ["Flagt", "Flagf"]),
  },
};
</script>

<style lang="less" scoped>

</style>