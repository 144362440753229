export default {
    namespaced: true,
    actions: {},
    mutations: {},
    state: {
        
        link: {
            map: [
                '明日票通',
                '套餐隐私协议',
				'隐私政策',
                '用户协议'
            ],
            zh: [
                '吉林省明日司服科技有限公司版权所有',
				'联系电话：400-600-9789'
            ],
            pis: [
                ' © 2022 - 2023 TENCENT. ALL RIGHTS RESERVED.',
            ],
            public: {
                _src: '	https://game.gtimg.cn/images/js/2018foot/logo/gswj.png',
                title: [
                    '吉ICP备2022006719号'
                ]
            },
            private: '© 2022-2023 版权所有'

        }
    },
    getters: {}
}