import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../pages/Main.vue'
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Main',
            component: Main,
            children:[
                
            ]
        }
    ]
})

const originnalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    // console.log(location);   // 路由的name和path
    return originnalPush.call(this, location).catch(err => err)
}
export default router