import Vue from 'vue'
import Vuex from 'vuex'
import CommonHeader from './comonHeader'
import CommonHome from './comonHome'
import ComonFooter from './comonFooter'
Vue.use(Vuex)

export default new Vuex.Store({
    modules:{
        CommonHeader,
        CommonHome,
        ComonFooter
    }

})