export default ({
    namespaced: true,
    actions: {},
    mutations: {
        roy(state, Name) {
            state.roys = Name
            // console.log(state.roys);
        },
        remen(state, Name) {
            state.remens = Name
            console.log(state.remens);
        },
        firstCals(state) {
            state.roys = state.synthetical.cals
            state.remens = state.selectLists.zzjx
            console.log(state.roys);
        }
    },
    state: {
        roys: [],
        remens: [],
        titleList: ['美好车生活', '个人中心', '前往目的地', '特惠', '限时特惠'],
        images: [
     //        new URL('../assets/1.png', import.meta.url).href,
     //       new URL('../assets/2.png', import.meta.url).href,
		   // new URL('../assets/3.png', import.meta.url).href,
		   // new URL('../assets/4.png', import.meta.url).href,
		   // new URL('../assets/5.png', import.meta.url).href,
        ],
        news: [
            { Name: 'cals', Tit: '综合' },
            { Name: 'notice', Tit: '公告' },
        ],
		
        synthetical: {
            HH: [
                '明日票通app是一款代驾司机端app，包含工牌扫码下单，自创单等新颖功能',
                '轻资产',
                '司机的定价权',
                '车队'
            ],
            cals: [
                {
                    title: '新闻',
                    content: '明日票通上线啦',
                    times: '12-25',
                    class: 'skyblue'
                },
                {
                    title: '公告',
                    content: '明日票通app-是一款代驾司机端app，包含工牌扫码下单，自创单等新颖功能',
                    times: '12-24',
                    class: 'yellow'
                },
                {
                    title: '公告',
                    content: '轻资产即将上线，尽情期待',
                    times: '12-07',
                    class: 'yellow'
                },
                {
                    title: '公告',
                    content: '司机的定价权',
                    times: '12-07',
                    class: 'yellow'
                },
            ],
            notice: [
                {
                    title: '公告',
                    content: '热衷于搞钱养家',
                    times: '11-07',
                    class: 'yellow'
                },
                {
                    title: '公告',
                    content: '大众创业',
                    times: '12-21',
                    class: 'yellow'
                },
                {
                    title: '公告',
                    content: '免费使用',
                    times: '10-07',
                    class: 'yellow'
                },
                {
                    title: '公告',
                    content: '君子竞争',
                    times: '12-03',
                    class: 'yellow'
                },
            ],
            Competition: [
                {
                    title: '新闻',
                    content: '轻资产即将上线，尽情期待',
                    times: '10-09',
                    class: 'orange'
                },
                {
                    title: '公告',
                    content: '大众创业',
                    times: '12-21',
                    class: 'yellow'
                },
				{
				    title: '公告',
				    content: '明日票通app-是一款代驾司机端app，包含工牌扫码下单，自创单等新颖功能',
				    times: '12-24',
				    class: 'yellow'
				},
                {
                    title: '公告',
                    content: '免费使用',
                    times: '10-07',
                    class: 'yellow'
                },
                {
                    title: '公告',
                    content: '君子竞争',
                    times: '12-03',
                    class: 'yellow'
                },
            ],
            
        },
      
       
        HeroUpdate: [
            {
                title: '英雄跟新',
                _src: 'https://img.crawler.qq.com/lolwebschool/0/JAutoCMS_LOLWeb_1d1b4a6d6a2fcc558507c0ba84815148/0',
                names: '首领行者 乌迪尔',
                content: '他踏寒风而来，在平衡与纷争中探寻弗雷尔卓德的未来'
            },
            {
                title: '新皮肤',
                _src: '	https://img.crawler.qq.com/lolwebschool/0/JAutoCMS_LOLWeb_cc4f6693d7dda79fc10a10de888395b0/0',
                names: '灵魂莲华2022',
                content: '一瞬心间 一念绽灵。灵魂莲华系列全新皮肤上线！'
            }
        ],
        zhizhen:[
            'https://img.crawler.qq.com/lolwebschool/0/JAutoCMS_LOLWeb_9804a271570fa09208031d53017d6437/0',
            'https://img.crawler.qq.com/lolwebschool/0/JAutoCMS_LOLWeb_a363944cdcae09cf08ea4be8a6bb3948/0'
        ],
        zeli:{
            _src:'https://img.crawler.qq.com/lolwebschool/0/JAutoCMS_LOLWeb_c218eab6d7d6e52809a8e3f294dc5b35/0',
            title:'周免·'
        }
        

    },
    getters: {}
})